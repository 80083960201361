@use '@cloudscape-design/design-tokens/index' as awsui;

$background-dark: #0f1b2a;
$background-light: #fff;

h1 {
  padding: awsui.$space-scaled-l;
}

.awsui-context-content-header {
  padding-top: awsui.$space-scaled-xxxl;
  background: #00000080 !important;
}

html, body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  color: awsui.$color-text-body-default;
  background: url("../public/maw.png") no-repeat center center fixed;
  background-size: cover;
}

/* 
  Root of React app, adds background that we can occlude it dynamically
  Dark mode gets dark background, light mode gets light background
  Backgrounds are semitransparent to allow the body image to show through
  Once app loads, background animates in
  -- UNLESS adhd mode is on, then we just keep it opaque to hide bg image
*/
#root {
  background-color: $background-light;
  transition: background-color 1.5s ease-in-out;
  min-height: 100vh;
}
.awsui-dark-mode #root {
  background-color: $background-dark;
}

body[data-adhd="false"] #root {
  background-color: transparentize($background-light, 0.5);
}
body[data-adhd="false"].awsui-dark-mode #root {
  background-color: transparentize($background-dark, 0.5);
}

.menu {
  height: 100%;
  padding: 50px;
  min-height: 95vh;
  box-sizing: border-box;
}

@media screen and (min-width: 600px) {
  input {
    min-width: 400px;
  }
  .wide-container {
    max-width: 60vw;
    margin: 0 auto;
  }
}

/* Add border to table columns */
td:not(:last-child) {
  border-right: var(--border-divider-list-width-hacikr, 1px) solid var(--color-border-divider-default-7s2wjw, #e9ebed);
}

/* Banned user peeps get strikethroughs */
button[data-banned="true"] {
  text-decoration: line-through !important;
}

/* Custom cards */
.maw-card {
  background-color: awsui.$color-background-container-content;
  border-radius: awsui.$border-radius-container;
  padding: awsui.$space-scaled-l;
  box-shadow: 0px 0px 1px 1px #e9ebed,0px 1px 8px 2px #0007161f;
  overflow: visible;
  margin: 0 awsui.$space-scaled-m;
}
.awsui-dark-mode .maw-card {
  box-shadow: 0px 0px 1px 1px #192534,0px 1px 8px 2px #000716;

}
.maw-card:hover {
  cursor: pointer;
  background-color: awsui.$color-background-dropdown-item-hover;
  box-shadow: 0px 0px 1px 1px #e9ebed,0px 1px 8px 2px #0007161f,0px 0px 0px 2px #e9ebed;
}
.awsui-dark-mode .maw-card:hover {
  background-color: #081120;
  box-shadow: 0px 0px 1px 1px #192534,0px 1px 8px 2px #000716,0px 0px 0px 2px #192534;
}


// THE LOADING EFFECT
.skeleton {
	background-color: awsui.$color-background-control-disabled;
  border-radius: awsui.$border-radius-container;
	// The shine that's going to move across the skeleton:
	background-image:			
			linear-gradient(
				90deg, 
				rgba(#fff, 0), 
				rgba(#fff, 0.5),
				rgba(#fff, 0)
			);
	background-size: 40px 100%; // width of the shine
	background-repeat: no-repeat; // No need to repeat the shine effect
	background-position: left -40px top 0; // Place shine on the left side, with offset on the left based on the width of the shine - see background-size
	animation: shine 1s ease infinite; // increase animation time to see effect in 'slow-mo'
}

@keyframes shine {
	to {
		// Move shine from left to right, with offset on the right based on the width of the shine - see background-size
		background-position: right -40px top 0;
	}
}

.skeleton.button {
  height: 34px;
  width: 200px;
}
